<template>
  <div>
    <div id="widthdemo" style="width: 96vw;height: 96vw;display: none"></div>
    <div ref="qrcodeprivateuse" style="width: 10vw;height: 10vw"></div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  name: 'QrCode',
  components: {},
  props: {
  },
  data:function () {
    return {
    }
  },
  mounted() {
  },
  methods:{
    generateQr(content){
      let width = window.getComputedStyle(document.getElementById("widthdemo")).width
      width = parseInt(width.substring(0,width.indexOf('.')))
      console.log("width",width)
      let qrcode = new QRCode(this.$refs.qrcodeprivateuse, {
        text: content, // 需要转换为二维码的内容
        width: width,
        height: width,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.address-op{
  text-align: left;
  font-size: 14px;
}
</style>
