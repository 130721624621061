<template>
  <div id="app">
    <address-op></address-op>
  </div>
</template>

<script>


import AddressOp from "@/components/AddressOp";
import QrCode from "@/components/QrCode";
export default {
  name: 'App',
  components: {QrCode, AddressOp},
  data() {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      }
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
