import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import Vuex from 'vuex'
Vue.use(Vuex)
Vue.use(ElementUI);
Vue.config.productionTip = false

// 创建一个新的 store 实例
const store = new Vuex.Store({
  state: {
    address: null,
    ownPermission: false,
  },
  mutations: {
    setAddress (state,address) {
      state.address = address
    },
    setOwnPermission (state,ownPermission) {
      state.ownPermission = ownPermission
    },
  }
})

const app = new Vue({
  render: h => h(App),
  store,
}).$mount('#app')

