<template>
  <div style="position: fixed;border: black 2px;z-index: 999;width: 96vw;height:96vw;top:25vh" v-if="show">
    <qrcode-stream  @decode="onDecode" @init="onInit"/>
    <el-button v-if="show" type="warning" size="mini" @click="show = false" style="z-index: 999">取消</el-button>
    <el-button v-if="show" type="primary" size="mini" @click="edit" style="z-index: 999">手动输入</el-button>
  </div>
</template>

<script>
import { QrcodeStream,QrcodeCapture } from 'vue-qrcode-reader'
export default {
  name: 'ScanQr',
  components:{QrcodeStream,QrcodeCapture },
  props: {
  },
  data:function () {
    return {
      callback: null,
      show: false,
      result: '',
    }
  },
  watch: {
    open(newValue, old){
      console.log(old,newValue)
      this.show = newValue
    }
  },
  mounted() {
  },
  methods:{
    open(callback){
      this.show = true
      if (callback) {
        this.callback = callback
      }
    },
    edit(){
      let res = prompt("手动输入");
      this.onDecode(res)
    },
    onDecode(result){
      this.result = result
      this.show = false
      if (this.callback != null && typeof this.callback === 'function') {
        this.callback(result)
      }
      console.log("scanQr result:",result)
    },
    // init方法是初始化调用摄像头,此时如果摄像头报错会有很多提示，请酌情处理
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = `ERROR: Camera access is only permitted in secure context.
            Use HTTPS or localhost rather than HTTP.`;
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.address-op{
  text-align: left;
  font-size: 14px;
}
</style>
